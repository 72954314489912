import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { InputProps, TextInput } from '../TextInput';

export interface PostalCodeInputProps extends InputProps {
  prefix: string;
}

export const PostalCodeInput = forwardRef<
  HTMLInputElement,
  PostalCodeInputProps
>(({ prefix, className, ...rest }, ref) => {
  return (
    <div className="relative">
      {prefix && (
        <span
          className={twMerge(
            'absolute left-0 top-0 flex h-full items-start',
            'py-[13px] pl-3 text-sm leading-6 text-black'
          )}
        >
          {prefix}
        </span>
      )}
      <TextInput
        ref={ref}
        placeholder=""
        type="text"
        maxLength={20}
        className={twMerge(
          className,
          !!prefix && 'pl-11',
          '[appearance:textfield]',
          '[&::-webkit-inner-spin-button]:appearance-none',
          '[&::-webkit-outer-spin-button]:appearance-none'
        )}
        {...rest}
      />
    </div>
  );
});

PostalCodeInput.displayName = 'PostalCodeInput';
