import { useCallback, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { twMerge } from 'tailwind-merge';
import { Tab } from '@headlessui/react';

import { ApiError, UserAddress } from 'epromo-types';
import { isDeliveryAddressB2c } from 'epromo-lib/utils/address';

import { AddressesListB2B } from '@components/molecules/AddressesListB2B';
import { AddressesListB2C } from '@components/molecules/AddressesListB2C';
import { PanelTitle } from '@components/atoms/PanelTitle';
import { Alert } from '@components/atoms/Alert';
import { AddressesState } from '@components/organisms/AutoSelectDeliveryAddressDialog';

export interface AddressesSelectionProps {
  onAddNew: () => void;
  onSelected: (address: UserAddress) => void;
  addressesData?: AddressesState;
  error?: ApiError | null;
  selectedAddressId?: UserAddress['id'];
}

const tabStyle = twMerge(
  'py-2 text-neutral-300',
  'font-bold flex-1',
  'outline-none',
  'text-sm md:text-xl lg:text-2xl'
);

export function AddressesSelection({
  error,
  onAddNew,
  onSelected,
  addressesData,
  selectedAddressId,
}: AddressesSelectionProps) {
  const { t } = useTranslation('common');
  const isB2cAddress = isDeliveryAddressB2c(selectedAddressId);
  const [selectedIndex, setSelectedIndex] = useState(
    selectedAddressId && isB2cAddress ? 1 : 0
  );

  const showOnlyBusinessAddr =
    addressesData &&
    addressesData.b2b.length >= 1 &&
    addressesData.b2c.length <= 0;
  const showOnlyPrivateAddr =
    addressesData &&
    addressesData.b2c.length >= 1 &&
    addressesData.b2b.length <= 0;
  const showAllAddr =
    addressesData &&
    addressesData.b2c.length > 0 &&
    addressesData.b2b.length > 0;

  const renderBusinessAddresses = useCallback(() => {
    return (
      <AddressesListB2B
        companyAddresses={addressesData?.b2b}
        onClick={onSelected}
        selectedAddressId={selectedAddressId}
      />
    );
  }, [addressesData?.b2b, onSelected, selectedAddressId]);

  const renderPrivateAddresses = useCallback(() => {
    return (
      <AddressesListB2C
        addresses={addressesData?.b2c}
        onClick={onSelected}
        onAddNew={onAddNew}
      />
    );
  }, [addressesData?.b2c, onAddNew, onSelected]);

  return (
    <>
      <PanelTitle
        className={'mb-0 text-base font-black md:mb-0 md:text-xl lg:text-2xl'}
      >
        {t('whereDeliver')}
      </PanelTitle>
      <p className="py-4 text-sm text-neutral-700 lg:text-lg">
        {t('whereDeliverDescr')}
      </p>

      <div className="mb-0 bg-white text-center">
        {showOnlyBusinessAddr && renderBusinessAddresses()}
        {showOnlyPrivateAddr && renderPrivateAddresses()}
        {showAllAddr && (
          <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
            <Tab.List
              className={twMerge(
                'flex w-full justify-around gap-7',
                'border-b-2 border-b-neutral-300 lg:border-b',
                'bg-white p-1',
                'absolute z-10 -ml-6 md:relative md:ml-0'
              )}
            >
              <Tab
                className={({ selected }) =>
                  twMerge(
                    tabStyle,
                    selected && 'text-business-500',
                    !selected && 'hover:text-secondary-500'
                  )
                }
              >
                {t('b2bAddr')}
              </Tab>
              <Tab
                className={({ selected }) =>
                  twMerge(
                    tabStyle,
                    selected && 'text-secondary-500',
                    !selected && 'hover:text-secondary-500'
                  )
                }
              >
                {t('b2cAddr')}
              </Tab>
              <span
                className={twMerge(
                  'bg-business-500 absolute -bottom-0.5 left-0',
                  selectedIndex === 1 && 'bg-secondary-500 left-1/2',
                  'h-0.5 w-1/2',
                  'duration-500'
                )}
              />
            </Tab.List>
            <Tab.Panels
              className={twMerge(
                'no-scrollbar max-h-[50vh] min-h-[4rem] overflow-y-auto',
                'pt-14 md:pt-2'
              )}
            >
              <Tab.Panel>{renderBusinessAddresses()}</Tab.Panel>
              <Tab.Panel>{renderPrivateAddresses()}</Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        )}
      </div>
      <Alert message={error?.message} />
    </>
  );
}
