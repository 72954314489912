import { useTranslation } from 'next-i18next';
import { useRef, useState } from 'react';
import { useAddAddress } from 'epromo-lib/hooks/useAddAddress';
import { useForm } from 'react-hook-form';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import {
  AddDeliveryAddressResponse,
  CountryEnum,
  DeliveryAddressDataInput,
} from 'epromo-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { clsx } from 'clsx';
import {
  components,
  ControlProps,
  CSSObjectWithLabel,
  InputProps,
  PlaceholderProps,
  SelectInstance,
  SingleValueProps,
} from 'react-select';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';

import { useToaster } from 'epromo-lib/hooks/useToaster';
import {
  getFindPostalCodeUrl,
  getPostalCodePrefix,
} from 'epromo-lib/utils/language';

// prettier-ignore
import {
  getAddDeliveryAddressSchema,
// eslint-disable-next-line max-len
} from "@components/organisms/SelectDeliveryAddressDialog/adddeliveryaddress.schema";
import { FindPostalCodeLink } from '@components/atoms/FindPostalCodeLink';
import { PostalCodeInput } from '@components/atoms/PostalCodeInput';
import { TextInput } from '@components/atoms/TextInput';
import { Alert, AlertVariant } from '@components/atoms/Alert';
import { Button, ButtonAppearance } from '@components/atoms/Button';
import BackIcon from '@components/atoms/assets/icons/back.svg';
import { FormField } from '@components/molecules/FormField';
import {
  AddressAutoComplete,
  GoogleAddress,
} from '@components/organisms/AddressAutoComplete';
import { PhoneInput } from '@components/organisms/PhoneInput';

const postalCodePrefix = getPostalCodePrefix();
const findPostalCodeUrl = getFindPostalCodeUrl();

type FindAddressDialogFormProps = {
  onBack?: () => void;
  onSuccess?: (response: AddDeliveryAddressResponse) => void;
  forceB2cStyle?: boolean;
};

const SearchControl = ({ children, ...props }: ControlProps<any, false>) => {
  return (
    <components.Control {...props}>
      <span>
        <MagnifyingGlassIcon
          className="text-secondary-500 h-5 w-5"
          aria-hidden="true"
        />
      </span>
      {children}
    </components.Control>
  );
};

const Placeholder = ({ children, ...props }: PlaceholderProps<any>) => {
  return (
    <components.Placeholder {...props}>
      <span className="ml-3 text-base font-normal text-neutral-300">
        {children}
      </span>
    </components.Placeholder>
  );
};

const SingleValue = ({ children, ...props }: SingleValueProps<any>) => (
  <components.SingleValue {...props}>
    <span
      className={clsx(
        'ml-2 block w-11/12 overflow-hidden text-ellipsis',
        'whitespace-nowrap text-base font-normal text-neutral-300'
      )}
    >
      {children}
    </span>
  </components.SingleValue>
);

const Input = (props: InputProps<any, true>) => {
  if (props.isHidden) {
    return <components.Input {...props} />;
  }
  return (
    <span className="ml-3">
      <components.Input {...props} />
    </span>
  );
};

export function FindAddressDialogForm({
  onBack,
  onSuccess,
  forceB2cStyle,
}: FindAddressDialogFormProps) {
  const selectRef = useRef<SelectInstance<any> | null>(null);
  const { t } = useTranslation('common');
  const [addressAdded, setAddressAdded] = useState(false);
  const [isAddServiceable, setIsAddServiceable] = useState(false);
  const [selectedItem, setSelectedItem] = useState<GoogleAddress | undefined>();

  const { toast } = useToaster();
  const { isPending, submitAddress, error } = useAddAddress({
    onSuccess: (response) => {
      setAddressAdded(true);
      setIsAddServiceable(response.isServiceable);
      if (response.isServiceable) {
        toast({
          type: 'success',
          message: t('newDeliveryAddressAddSuccess'),
          hideProgressBar: true,
        });
      } else {
        toast({
          type: 'warning',
          message: t('newDeliveryAddressAddSuccessNotService'),
          hideProgressBar: true,
        });
      }
      onSuccess?.(response);
    },
  });

  const { setValue, control, handleSubmit, register, watch, reset } =
    useForm<DeliveryAddressDataInput>({
      resolver: yupResolver(getAddDeliveryAddressSchema(t)),
    });

  const latitude = watch('latitude');

  return (
    <>
      {onBack && (
        <div className="px-6 text-left md:px-8">
          <Button
            className="h-6 px-0 py-0"
            appearance={ButtonAppearance.SMALL_TEXT}
            type="button"
            onClick={onBack}
          >
            <BackIcon />
          </Button>
        </div>
      )}
      <div className="text-center text-xl font-black">{t('addNewAddress')}</div>
      <p className="pb-8 pt-4 text-center font-normal text-neutral-700">
        {t('addingAddressBenefit')}
      </p>
      <form onSubmit={handleSubmit(submitAddress)}>
        <SimpleBar
          style={{
            height: '630px',
          }}
        >
          <div
            className={clsx(
              'relative min-h-[8rem] overflow-y-auto px-6 md:px-8',
              'no-scrollbar mb-0 text-left',
              'flex flex-col gap-y-4',
              'rounded-xl bg-white'
            )}
          >
            <FormField
              id="address"
              control={control}
              isRequired
              className="px-1 md:px-6"
            >
              <AddressAutoComplete
                isRounded
                defaultSelectedItem={selectedItem}
                selectRef={selectRef}
                styles={{
                  input: (provided: CSSObjectWithLabel) => ({
                    ...provided,
                    padding: 0,
                    margin: 0,
                    marginLeft: '8px',
                  }),
                }}
                components={{
                  DropdownIndicator: null,
                  //@ts-ignore
                  Control: SearchControl,
                  Placeholder,
                  SingleValue,
                }}
                placeholder={t('streetNameOrZip')}
                name="google-street"
                onChange={(
                  {
                    zipCode,
                    country,
                    address,
                    houseNumber,
                    city,
                    latitude,
                    longitude,
                    apartmentNumber,
                  },
                  googleAddress
                ) => {
                  setValue('address', address);
                  setValue('houseNumber', houseNumber);
                  setValue('city', city);
                  setValue('zipCode', zipCode);
                  setValue('country', country as CountryEnum);
                  setValue('latitude', latitude);
                  setValue('longitude', longitude);
                  setValue('apartmentNumber', apartmentNumber);
                  setSelectedItem(googleAddress);
                }}
              />
            </FormField>
            {latitude && (
              <div className="flex flex-col gap-y-4 px-1 md:px-6">
                <FormField
                  label={t('deliveryMobile')}
                  id="phoneNumber"
                  control={control}
                  isRequired
                >
                  <PhoneInput name="phoneNumber" control={control} />
                </FormField>
                <FormField
                  label={t('houseNo')}
                  id="houseNumber"
                  control={control}
                  isRequired
                >
                  <TextInput
                    placeholder=""
                    type="text"
                    maxLength={255}
                    {...register('houseNumber')}
                  />
                </FormField>
                <FormField
                  label={t('apartmentNo')}
                  id="apartmentNumber"
                  control={control}
                  className="w-2/3"
                >
                  <TextInput
                    placeholder=""
                    type="text"
                    maxLength={255}
                    {...register('apartmentNumber')}
                  />
                </FormField>
                <FormField
                  isRequired
                  label={t('city')}
                  id="city"
                  className="w-2/3"
                  control={control}
                >
                  <TextInput
                    placeholder=""
                    type="text"
                    maxLength={255}
                    {...register('city')}
                  />
                </FormField>
                <div className="flex w-full flex-row md:w-2/3">
                  <div className="basis-2/3 md:basis-1/2">
                    <FormField
                      isRequired
                      label={t('postalCode')}
                      id="zipCode"
                      control={control}
                    >
                      <PostalCodeInput
                        prefix={postalCodePrefix}
                        {...register('zipCode')}
                      />
                    </FormField>
                  </div>
                  <FindPostalCodeLink
                    className={clsx('basis-3/4 pb-4 md:basis-1/2')}
                    href={findPostalCodeUrl}
                  >
                    {t('findPostal')}
                  </FindPostalCodeLink>
                </div>
                <FormField label={t('addInfo')} id="comment" control={control}>
                  <TextInput
                    placeholder={t('exampleDoorCode')}
                    type="text"
                    maxLength={550}
                    {...register('comment')}
                  />
                </FormField>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-y-8 px-14 pb-8 pt-4">
            <Alert message={error?.message} />
            {addressAdded && !isAddServiceable && (
              <Alert
                message={t('notServiceableAddress')}
                type={AlertVariant.INFO}
              />
            )}
          </div>
        </SimpleBar>
        {latitude && (
          <Button
            className={clsx(
              'rounded-b-xl rounded-t-none',
              forceB2cStyle && 'bg-primary-500 text-white',
              forceB2cStyle && 'hover:bg-primary-600 focus:bg-primary-600'
            )}
            fluid
            type="submit"
            loading={isPending}
          >
            {t('saveAddress')}
          </Button>
        )}
      </form>
    </>
  );
}
