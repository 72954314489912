import Link from 'next/link';
import { AnchorHTMLAttributes, DetailedHTMLProps } from 'react';
import { twMerge } from 'tailwind-merge';

export interface AProps
  extends Omit<
    DetailedHTMLProps<
      AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >,
    'href'
  > {
  href: string;
  prefetch?: boolean;
}

export function A({ href, children, className, prefetch, ...rest }: AProps) {
  return (
    <Link href={href} legacyBehavior prefetch={prefetch}>
      <a
        className={twMerge(
          'text-primary text-sm font-medium hover:text-black',
          className
        )}
        {...rest}
      >
        {children}
      </a>
    </Link>
  );
}
