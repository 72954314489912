import { clsx } from 'clsx';
import { useTranslation } from 'next-i18next';

import { AddressTypeEnum, UserAddress } from 'epromo-types';
import { useDeliveryAddress } from 'epromo-lib/hooks/useDeliveryAddress';

import { HelperText } from '@components/atoms/HelperText';

export interface AddressesListProps {
  addresses: UserAddress[];
  onClick: (data: UserAddress) => void;
  type?: AddressTypeEnum;
}

export function AddressesList({ addresses, onClick }: AddressesListProps) {
  const { t } = useTranslation('common');
  const { getAddressId } = useDeliveryAddress();
  const selectedAddressId = getAddressId();

  if (!(addresses && addresses.length)) return null;

  return (
    <ul className="px-4 text-neutral-800">
      {addresses.map((address) => (
        <li
          className={clsx(
            'flex cursor-pointer items-center gap-x-3 py-3',
            !address.isServiceable && 'opacity-50'
          )}
          key={address.id}
          onClick={() => onClick(address)}
        >
          <span
            className={clsx(
              'h-5 w-5 shrink-0 rounded-full border border-neutral-300',
              address.id === selectedAddressId &&
                'border-secondary-500 border-8'
            )}
          />
          <div className="text-left">
            {address.title && (
              <HelperText
                text={address.title}
                className={clsx('text-sm font-normal text-black')}
              />
            )}
            <p className={clsx('text-base font-medium text-black')}>
              {address.address}
            </p>
            {!address.isServiceable && <HelperText text={t('noService')} />}
          </div>
        </li>
      ))}
    </ul>
  );
}

AddressesList.displayName = 'AddressesList';
