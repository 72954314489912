import { useEffect, useRef, useState } from 'react';
import { clsx } from 'clsx';
import { Disclosure } from '@headlessui/react';

import { AddressTypeEnum, UserAddress, UserAddressB2b } from 'epromo-types';
import { isDeliveryAddressB2c } from 'epromo-lib/utils/address';

import { Icon } from '@components/atoms/Icon';

import { AddressesList } from '../AddressesList/AddressesList';

export interface AddressesListB2BProps {
  companyAddresses?: UserAddressB2b[];
  onClick: (data: UserAddress) => void;
  selectedAddressId?: UserAddress['id'];
}

export function AddressesListB2B({
  companyAddresses,
  onClick,
  selectedAddressId,
}: AddressesListB2BProps) {
  const isB2cAddress = isDeliveryAddressB2c(selectedAddressId);
  const [openedIndex, setOpenedIndex] = useState(
    isB2cAddress
      ? 0
      : companyAddresses?.findIndex(
          ({ addresses }) =>
            !!addresses.find(({ id }) => id === selectedAddressId)
        )
  );
  const itemsRef = useRef<Array<HTMLButtonElement | null>>([]);

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, companyAddresses?.length);
  }, [companyAddresses]);

  if (!(companyAddresses && companyAddresses.length)) return null;

  return (
    <ul>
      {companyAddresses.map((company, i) => (
        <Disclosure
          as={'li'}
          key={company.title}
          defaultOpen={i === openedIndex}
        >
          {({ open, close }) => {
            if (open && openedIndex !== undefined && openedIndex !== i) {
              close(itemsRef.current[openedIndex] ?? undefined);
            }
            return (
              <>
                <Disclosure.Button
                  key={company.title}
                  // @ts-ignore
                  onClick={() => {
                    return setOpenedIndex(i);
                  }}
                  ref={(el) => (itemsRef.current[i] = el)}
                  className={clsx('flex w-full justify-between', 'pt-2')}
                >
                  <p className="text-left text-base font-medium">
                    {company.title}
                  </p>
                  <Icon
                    name={'expand'}
                    className={clsx(
                      'h-6 w-6 shrink-0 duration-300',
                      open && 'rotate-180'
                    )}
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  <AddressesList
                    addresses={company.addresses}
                    onClick={onClick}
                    type={AddressTypeEnum.foodService}
                  />
                </Disclosure.Panel>
              </>
            );
          }}
        </Disclosure>
      ))}
    </ul>
  );
}

AddressesListB2B.displayName = 'AddressesListB2B';
