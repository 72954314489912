import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { useRouter } from 'next/router';
import { PlusIcon } from '@heroicons/react/24/solid';
import { useQueryClient } from '@tanstack/react-query';

import { ApiError, UserAddress, DeliveryAddressContract } from 'epromo-types';
import { useDeliveryAddress } from 'epromo-lib/store/auth';

import {
  useUserProfile,
  userProfileSelector,
} from 'epromo-lib/store/auth/user-profile';
import { resolveFirstCategoryUrl } from 'epromo-lib/utils/slug';

import { isDeliveryAddressB2c } from 'epromo-lib/utils/address';
import { useToaster } from 'epromo-lib/hooks/useToaster';
import { useCart } from 'epromo-lib/hooks/useCart';
import { PRIVATE_ROUTES } from 'epromo-lib/constants';
import { changeTheme } from 'epromo-lib/utils/helpers';
import { useAddresses } from 'epromo-lib/hooks/useAddresses';
import { useInitialCategories } from 'epromo-lib/hooks/useInitialCategories';

import {
  useGetFullWishlist,
  useGetTemplates,
} from 'epromo-lib/hooks/useFavorites';

// eslint-disable-next-line max-len
import { ResendCodeDialogForm } from '@components/organisms/ResendCodeDialogForm';
// eslint-disable-next-line max-len
import { FindAddressDialogForm } from '@components/organisms/FindAddressDialogForm';
import { AddressesSelection } from '@components/organisms/AddressesSelection';
import { ConfirmPhoneAddress } from '@components/organisms/ConfirmPhoneAddress';
import { FormPanel } from '@components/molecules/FormPanel';

import { Dialog } from '../Dialog';

const paddingStyle = 'px-6 py-8 md:px-10 lg:px-20';

export interface SelectAddressDialogProps {
  isOpened: boolean;
  onClose: () => void;
  error?: ApiError | null;
}

export function SelectDeliveryAddressDialog({
  isOpened,
  onClose,
  error,
}: SelectAddressDialogProps) {
  const { t } = useTranslation('common');
  const router = useRouter();
  const userProfile = useUserProfile(userProfileSelector.userProfile);
  const isLoggedIn = !!userProfile;
  const { setAddressId, getAddressId } = useDeliveryAddress();
  const { setAddress } = useUserProfile();
  const addressId = getAddressId();
  const [tempAddAddress, setTempAddAddress] = useState<
    UserAddress | undefined
  >();
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [showAddressConfirmation, setShowAddressConfirmation] = useState(false);
  const [showEnterPhone, setShowEnterPhone] = useState(false);
  const { data: addressesData, refetch: fetchAddresses } = useAddresses();
  const { flatInventoryList, categoryId } = useInitialCategories(false);
  const { fetchTemplates } = useGetTemplates();
  const { fetchFullWishlist } = useGetFullWishlist();

  const hasB2cAddress = !!addressesData?.b2c.length;
  const hasB2bAddress = !!addressesData?.b2b.length;
  const hasTwoTabs = hasB2cAddress && hasB2bAddress;
  const isMissingAddress = !hasB2bAddress && !hasB2cAddress;

  const { similar } = router.query as { similar?: boolean };
  const pushHome = !!similar;

  const queryClient = useQueryClient();
  const { fetchCheckoutData, resetCartItemsDictionary } = useCart();

  const setUserAddress = (address: UserAddress) => {
    setAddressId(address.id);
    setAddress(address);
    fetchTemplates();
    fetchFullWishlist();
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchAddresses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const { toast } = useToaster();

  const successToast = useCallback(() => {
    toast({
      type: 'success',
      message: t('deliveryAddressSelectedSuccess'),
      hideProgressBar: true,
      toastId: 'addressChangedSuccessToast',
    });
  }, [t, toast]);

  const isInnerPage = !!document.getElementById('innerPage');

  const onAfterAddressChange = useCallback(
    async (address: UserAddress) => {
      const isB2cAddress = isDeliveryAddressB2c(address.id);
      resetCartItemsDictionary();
      changeTheme(isB2cAddress ? 'b2c' : 'b2b');

      onClose();
      successToast();

      fetchCheckoutData(undefined, isB2cAddress);
      await queryClient.refetchQueries({
        predicate: (query) =>
          query.queryKey[0] === 'cat-products' &&
          !!query.queryKey.find((key) => key === address.id) &&
          !!query.queryKey.find((key) => key === categoryId),
      });

      const categoryProductsCache = queryClient.getQueriesData({
        queryKey: ['cat-products'],
      });

      const cachedData = (categoryProductsCache.find(
        ([queryKey, _pagesObj]) =>
          !!queryKey.find((key) => key === address.id) &&
          !!queryKey.find((key) => key === categoryId)
      ) ?? [])[1];

      const hasItemsInList =
        (cachedData as any)?.pages?.[0]?.products?.length > 0;

      const triggerRedirect =
        !hasItemsInList &&
        !PRIVATE_ROUTES.find((route) => router.pathname.includes(route)) &&
        router.pathname != '/' &&
        !isInnerPage;

      if (triggerRedirect) {
        router.push(resolveFirstCategoryUrl(router.locale, flatInventoryList));
      }

      await queryClient.refetchQueries({ queryKey: ['credit-check'] });
      await queryClient.refetchQueries({ queryKey: ['user-active-orders'] });
    },
    [categoryId, flatInventoryList, router]
  );

  useEffect(() => {
    setShowAddAddress(false);
    setShowAddressConfirmation(false);
    setShowEnterPhone(false);
  }, [isOpened]);

  const handleAddressSelect = useCallback(
    async (address: UserAddress) => {
      if (!address.isServiceable) {
        return;
      }
      setTempAddAddress(address);
      if (!address.phoneNumberConfirmationRequired) {
        setUserAddress(address);
        if (pushHome) {
          await onAfterAddressChange(address);
          await router.push(
            resolveFirstCategoryUrl(router.locale, flatInventoryList)
          );
        } else {
          await onAfterAddressChange(address);
        }
      } else {
        setShowAddressConfirmation(true);
      }
    },
    [
      flatInventoryList,
      onAfterAddressChange,
      pushHome,
      router,
      setAddress,
      setAddressId,
    ]
  );

  const renderDialogContent = useCallback(() => {
    if (isMissingAddress && !showAddAddress && !showAddressConfirmation) {
      return (
        <FormPanel
          onSubmit={() => setShowAddAddress(true)}
          title={t('whereDeliver')}
          description={t('missingDeliveryAddressDescripiton')}
          buttonText={t('addNewAddress')}
          buttonIcon={<PlusIcon className="h-6 w-6 fill-white" />}
        />
      );
    } else if (!showEnterPhone && !showAddAddress && !showAddressConfirmation) {
      return (
        <div className={paddingStyle}>
          <AddressesSelection
            error={error}
            addressesData={addressesData}
            onSelected={handleAddressSelect}
            onAddNew={() => {
              setShowAddAddress(true);
            }}
            selectedAddressId={addressId}
          />
        </div>
      );
    } else if (!showEnterPhone && showAddAddress) {
      return (
        <div className="pt-6">
          <FindAddressDialogForm
            onBack={() => setShowAddAddress(false)}
            onSuccess={async () => {
              if (isMissingAddress) {
                router.reload();
              }
              await fetchAddresses();
              setShowAddAddress(false);
              onClose();
            }}
          />
        </div>
      );
    } else if (!showEnterPhone && showAddressConfirmation) {
      return (
        <div className={paddingStyle}>
          <ConfirmPhoneAddress
            onNewPhoneRequested={() => {
              setShowEnterPhone(true);
              setShowAddressConfirmation(false);
            }}
            onConfirmed={() => {
              setShowAddressConfirmation(false);
              if (tempAddAddress) {
                setUserAddress(tempAddAddress);
              }
            }}
            phoneNumber={
              addressesData?.b2c?.find(({ id }) => id === tempAddAddress?.id)
                ?.phoneNumber
            }
            onBack={() => setShowAddressConfirmation(false)}
            addressId={tempAddAddress?.id ?? ''}
          />
        </div>
      );
    } else if (showEnterPhone) {
      return (
        <div className="pt-8">
          <ResendCodeDialogForm
            addressId={tempAddAddress?.id ?? ''}
            onResent={(newAddress: DeliveryAddressContract) => {
              setShowEnterPhone(false);
              setShowAddressConfirmation(true);
              fetchAddresses();
              handleAddressSelect(newAddress as UserAddress);
            }}
          />
        </div>
      );
    } else return null;
  }, [
    addressId,
    addressesData,
    error,
    fetchAddresses,
    handleAddressSelect,
    isMissingAddress,
    onClose,
    router,
    setAddress,
    setAddressId,
    showAddAddress,
    showAddressConfirmation,
    showEnterPhone,
    t,
    tempAddAddress,
  ]);

  return (
    <Dialog
      isOpened={isOpened}
      onClose={() => {
        if (addressId) {
          onClose();
        }
      }}
      isClosable={!!addressId}
      className={twMerge(
        hasTwoTabs && 'mt-[10vh]',
        isMissingAddress ? 'max-w-md' : 'md:max-w-2xl lg:max-w-3xl xl:max-w-4xl'
      )}
      verticalPosition={hasTwoTabs ? 'top' : 'center'}
    >
      <div
        className={twMerge(
          'text-center',
          'rounded-2xl bg-white',
          'sm:mx-auto sm:w-full'
        )}
      >
        {renderDialogContent()}
      </div>
    </Dialog>
  );
}
