import { useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { VerificationInput } from 'epromo-types';
// eslint-disable-next-line max-len
import { useAddressPhoneVerification } from 'epromo-lib/hooks/useAddressPhoneVerification';
//prettier-ignore
import { getVerifyPhoneSchema }
  from '@components/organisms/EmailConfirmationForm/form.schema';
import { Button, ButtonAppearance } from '@components/atoms/Button';
import { PanelTitle } from '@components/atoms/PanelTitle';
import { Alert } from '@components/atoms/Alert';
import BackIcon from '@components/atoms/assets/icons/back.svg';
import { FormField } from '@components/molecules/FormField';
import { PhoneVerification } from '@components/organisms/PhoneVerification';

type ConfirmPhoneAddressProps = {
  onBack?: () => void;
  onConfirmed: () => void;
  onNewPhoneRequested: () => void;
  phoneNumber: string | undefined | null;
  addressId: string;
  variant?: 'signUp' | 'newAddress';
};

export function ConfirmPhoneAddress({
  phoneNumber,
  onBack,
  onConfirmed,
  onNewPhoneRequested,
  addressId,
  variant = 'newAddress',
}: ConfirmPhoneAddressProps) {
  const { t } = useTranslation('common');
  const {
    control,
    watch,
    getValues,
    formState: { isValid },
  } = useForm<VerificationInput>({
    resolver: yupResolver(getVerifyPhoneSchema(t)),
  });
  const {
    changeNumber,
    changeNumberIsLoading,
    verifyNumber,
    verifyNumberErr,
    verifyNumberIsLoading,
  } = useAddressPhoneVerification({
    onPhoneVerified: () => {
      onConfirmed();
    },
  });

  useEffect(() => {
    if (variant === 'newAddress') {
      changeNumber({ phoneNumber, addressId });
    }
  }, []);

  const code = watch('code');

  useEffect(() => {
    if (code && isValid) {
      verifyNumber(getValues());
    }
  }, [code, isValid, getValues]);

  return (
    <>
      <div className="px-8 pb-8 text-left">
        {onBack && (
          <Button
            className="h-6 px-0 py-0"
            appearance={ButtonAppearance.SMALL_TEXT}
            type="button"
            onClick={onBack}
          >
            <BackIcon />
          </Button>
        )}
        <PanelTitle className="mb-0 text-center text-base md:mb-0 md:text-base">
          {t('phoneVerification')}
        </PanelTitle>
        <p className="py-4 text-center text-xs text-gray-500">
          {t('enterPhoneVerCode', { count: 6, phoneNumber })}
        </p>
        <form className="mx-auto mb-4 flex flex-row justify-center">
          <FormField id="code" control={control}>
            <PhoneVerification name="code" control={control} />
          </FormField>
        </form>

        <Alert message={verifyNumberErr?.message} className="mb-4 max-w-none" />

        <Button
          appearance={ButtonAppearance.SECONDARY}
          fluid
          type="button"
          onClick={() => {
            changeNumber({ phoneNumber, addressId });
          }}
          loading={verifyNumberIsLoading || changeNumberIsLoading}
        >
          {t('resendCode')}
        </Button>
        <Button
          appearance={ButtonAppearance.TEXT}
          fluid
          type="button"
          onClick={() => {
            onNewPhoneRequested();
          }}
          disabled={changeNumberIsLoading || verifyNumberIsLoading}
        >
          {t('enterOtherPhone')}
        </Button>
      </div>
    </>
  );
}
