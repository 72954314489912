import { useEffect, useState } from 'react';
// eslint-disable-next-line max-len
import usePlaces from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import getConfig from 'next/config';
import { useTranslation } from 'next-i18next';
import {
  ControlProps,
  components,
  PlaceholderProps,
  SingleValue,
  InputProps,
} from 'react-select';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';

import { DropDown, DropDownProps } from '@components/molecules/DropDown';

const { publicRuntimeConfig } = getConfig();

type GoogleAddressTerm = {
  offset: number;
  value: string;
};

export type GoogleAddress = {
  place_id: string;
  description: string;
  terms: GoogleAddressTerm[];
};

type AddressComponent = {
  long_name: string;
  short_name: string;
  types: string[];
};

type AddressGeometry = {
  location: { lat: () => number; lng: () => number };
};

type AddressMetaInfo = {
  address: string;
  houseNumber: string;
  zipCode: string;
  country: string;
  latitude: number;
  longitude: number;
  city: string;
  apartmentNumber: string;
};

type PlaceDetail = {
  address_components: AddressComponent[];
  geometry: AddressGeometry;
};

export interface AddressAutoCompleteProps<GoogleAddress>
  extends Omit<DropDownProps<GoogleAddress, GoogleAddress>, 'onChange'> {
  onChange: (data: AddressMetaInfo, address: GoogleAddress) => void;
  isRounded: boolean;
  defaultSelectedItem?: GoogleAddress;
}

const findAddressComponentByType = (
  components: AddressComponent[],
  type: string
): string => {
  const result = components.find(
    (component) => component.types.join('_').indexOf(type) === 0
  );
  if (!result) {
    return '';
  }
  return result.short_name;
};

const SearchControl = ({ children, ...props }: ControlProps<any, false>) => {
  return (
    <components.Control {...props}>
      <span>
        <MagnifyingGlassIcon
          className="text-secondary-500 mr-2 h-5 w-5"
          aria-hidden="true"
        />
      </span>
      {children}
    </components.Control>
  );
};

const Placeholder = ({ children, ...props }: PlaceholderProps<any>) => {
  return (
    <components.Placeholder {...props}>
      <span className="ml-3 text-base font-normal text-neutral-300">
        {children}
      </span>
    </components.Placeholder>
  );
};

const Input = (props: InputProps<any>) => (
  <components.Input {...props} isHidden={false} />
);

export function AddressAutoComplete({
  onChange,
  defaultSelectedItem,
  ...rest
}: AddressAutoCompleteProps<GoogleAddress>) {
  const { t } = useTranslation('common');
  const apiKey = publicRuntimeConfig.googleMapsApiKey;
  const {
    placePredictions,
    getPlacePredictions,
    placesService,
    isPlacePredictionsLoading,
  } = usePlaces({
    apiKey,
  });
  const [options, setOptions] = useState<GoogleAddress[]>();
  const [selectedItem, setSelectedItem] = useState<GoogleAddress | undefined>(
    defaultSelectedItem
  );

  useEffect(() => {
    if (placePredictions.length) {
      setOptions(placePredictions);
    }
  }, [placePredictions]);

  useEffect(() => {
    setSelectedItem(defaultSelectedItem);
  }, [defaultSelectedItem]);

  const [inputValue, setInputValue] = useState('');

  return (
    <DropDown
      controlShouldRenderValue={false}
      isLoading={isPlacePredictionsLoading}
      placeholder={t('search')}
      {...rest}
      isMulti={false}
      value={selectedItem}
      onInputChange={(inputValue, { action }) => {
        // Google search does not like hyphens `-`. This matches strings like
        // `20-14`, `3C-15`, `12 - 10` etc. and replaces them with whitespace.
        // Which returns better results from google api.

        const houseNoAndFlatNoRegex = /\b(\d+)\s*([a-zA-Z]?)\s*-\s*(\d+)\b/g;

        const inputValueNoHyphen = inputValue.replace(
          houseNoAndFlatNoRegex,
          (match, digits, letters, moreDigits) =>
            digits + (letters || '') + ' ' + moreDigits
        );

        getPlacePredictions({ input: inputValueNoHyphen });

        if (action === 'input-change') {
          setInputValue(inputValue);
        }
      }}
      inputValue={inputValue}
      filterOption={(opt) => !!opt}
      noOptionsMessage={t('noSrchRes')}
      onChange={(item) => {
        const singleValue = item as SingleValue<GoogleAddress>;
        const newValue = {
          description: '',
          terms: [],
          place_id: '',
        };
        placesService?.getDetails(
          {
            // @ts-ignore
            placeId: singleValue?.place_id,
          },
          (details: PlaceDetail) => {
            const address = findAddressComponentByType(
              details.address_components,
              'route'
            );
            const houseNumber = findAddressComponentByType(
              details.address_components,
              'street_number'
            );
            const apartmentNumber = findAddressComponentByType(
              details.address_components,
              'subpremise'
            );
            const zipCode = findAddressComponentByType(
              details.address_components,
              'postal_code'
            );
            const country = findAddressComponentByType(
              details.address_components,
              'country'
            );
            const city = findAddressComponentByType(
              details.address_components,
              'locality'
            );

            newValue.description = address;
            setSelectedItem(newValue);

            onChange(
              {
                address,
                apartmentNumber,
                houseNumber,
                city,
                zipCode,
                country: country.toLowerCase(),
                latitude: details.geometry.location.lat() || 0,
                longitude: details.geometry.location.lng() || 0,
              },
              newValue
            );
          }
        );
        setInputValue(singleValue ? singleValue.description : '');
      }}
      defaultOptions={options}
      getOptionLabel={(x) => x.description}
      getOptionValue={(x) => x.description}
      components={{
        DropdownIndicator: null,
        // @ts-ignore
        Control: SearchControl,
        Placeholder,
        Input,
      }}
    />
  );
}
