/* eslint-disable max-len */
import { useTranslation } from 'next-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { clsx } from 'clsx';

import { DeliveryAddressContract } from 'epromo-types';
import { useAddressPhoneVerification } from 'epromo-lib/hooks/useAddressPhoneVerification';

import { Alert } from '@components/atoms/Alert';
import { Button } from '@components/atoms/Button';
import { PanelTitle } from '@components/atoms/PanelTitle';
import { FormField } from '@components/molecules/FormField';
import { getDeliveryPhoneSchema } from '@components/organisms/SelectDeliveryAddressDialog/adddeliveryaddress.schema';
import { PhoneInput } from '@components/organisms/PhoneInput';

type ResendCodeDialogFormProps = {
  onResent: (newAddress: DeliveryAddressContract) => void;
  addressId: string;
};

type DeliveryAddressPhone = {
  phoneNumber: string;
};

export function ResendCodeDialogForm({
  onResent,
  addressId,
}: ResendCodeDialogFormProps) {
  const { t } = useTranslation('common');

  const { changeNumber, changeNumberErr, changeNumberIsLoading } =
    useAddressPhoneVerification({
      onCodeResent: (newAddress: DeliveryAddressContract) => {
        onResent(newAddress);
      },
    });

  const { control, handleSubmit } = useForm<DeliveryAddressPhone>({
    resolver: yupResolver(getDeliveryPhoneSchema(t)),
  });

  const submitForm = ({ phoneNumber }: DeliveryAddressPhone) => {
    changeNumber({ phoneNumber, addressId });
  };

  return (
    <>
      <div className="mb-8 px-8 text-center">
        <PanelTitle className="mb-0 text-base md:mb-0 md:text-base">
          {t('phoneVer')}
        </PanelTitle>
      </div>
      <form onSubmit={handleSubmit(submitForm)}>
        <div
          className={clsx(
            'relative min-h-[8rem] overflow-y-auto px-8',
            'no-scrollbar mb-0 text-left',
            'flex flex-col space-y-2',
            'rounded-xl bg-white '
          )}
        >
          <FormField
            label={t('deliveryMobile')}
            id="phoneNumber"
            control={control}
            isRequired
          >
            <PhoneInput name="phoneNumber" control={control} />
          </FormField>
        </div>
        <div className="px-8 pb-8">
          <Alert message={changeNumberErr?.message} className="max-w-2xl" />
        </div>
        <Button
          className="rounded-b-xl rounded-t-none"
          fluid
          type="submit"
          loading={changeNumberIsLoading}
        >
          {t('resendCode')}
        </Button>
      </form>
    </>
  );
}
