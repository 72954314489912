import * as yup from 'yup';

import { DeliveryAddressDataInput } from 'epromo-types';

import { I18 } from '../../../utils';

export const getDeliveryPhoneSchema = (t: I18) => {
  const required = t('required', { ns: 'common' });
  return yup.object().shape({
    phoneNumber: yup.string().required(required).typeError(required),
  });
};

export const getAddDeliveryAddressSchema = (t: I18) => {
  const required = t('required', { ns: 'common' });
  return yup.object().shape({
    phoneNumber: yup.string().required(required).typeError(required),
    address: yup.string().required(required).typeError(required),
    houseNumber: yup.string().required(required).typeError(required),
    city: yup.string().required(required).typeError(required),
    zipCode: yup
      .string()
      .required(required)
      .typeError(required)
      .matches(/^[0-9]+$/, t('invalidPostCode', { ns: 'common' })),
  }) as yup.ObjectSchema<DeliveryAddressDataInput>;
};
