import { twMerge } from 'tailwind-merge';

import { Icon } from '../Icon';
import { A, AProps } from '../A';

export const FindPostalCodeLink = ({ className, ...rest }: AProps) => {
  return (
    <div
      className={twMerge(
        'flex flex-row items-end pl-5',
        'justify-end md:justify-start',
        'gap-x-2',
        className
      )}
    >
      <Icon name="back" className="-mb-0.5 rotate-[135deg]" />
      <A target="_blank" {...rest} />
    </div>
  );
};

FindPostalCodeLink.displayName = 'FindPostalCodeLink';
