import { PlusIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'next-i18next';

import { AddressTypeEnum, UserAddress } from 'epromo-types';

import { Button, ButtonAppearance } from '@components/atoms/Button';

import { AddressesList } from '../AddressesList/AddressesList';

export interface AddressesListB2CProps {
  addresses?: UserAddress[];
  onClick: (data: UserAddress) => void;
  onAddNew: () => void;
}

export function AddressesListB2C({
  addresses,
  onClick,
  onAddNew,
}: AddressesListB2CProps) {
  const { t } = useTranslation('common');
  if (!(addresses && addresses.length)) return null;

  return (
    <div className="p-4 pt-2">
      <AddressesList
        addresses={addresses}
        onClick={onClick}
        type={AddressTypeEnum.cashNCarry}
      />
      <Button
        onClick={onAddNew}
        fluid
        className="mt-6"
        appearance={ButtonAppearance.SECONDARY}
        iconBefore={<PlusIcon className="h-6 w-6 font-bold" />}
      >
        {t('addNewAddress')}
      </Button>
    </div>
  );
}

AddressesListB2C.displayName = 'AddressesListB2C';
